import { Box, CardMedia, Stack } from '@mui/material'
import {
    isMicrosoftTeamsTest,
    useGenerateTeamsDeepLink
} from '@thriveglobal/thrive-web-core'
import { ErrorBoundary } from '@thriveglobal/thrive-web-errors'
import {
    ConfettiExplosion,
    CoreTypography,
    LeafCloseButton,
    LeafCopyButton,
    LeafFixedMediaCard,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { AssessmentAttemptSuccess } from '../../../graphql/generated/autogenerated'
import { useIsSmallScreen } from '../../../hooks/useIsSmallScreen'
import { defaultErrorBoundaryProps } from '../../../utils/defaultErrorBoundaryProps'
import { getJourneyColorPalette } from '../../utils/getJourneyColorPalette'
import { Avo } from '@thriveglobal/thrive-web-tracking'

const messages = defineMessages({
    shareBtn: {
        defaultMessage: 'Copy share link',
        description: 'label for share button'
    },
    shareLinkTitle: {
        defaultMessage:
            "I'm a {title}! Discover your well-being identity by taking your Thrive Global assessment here.",
        description: 'share link title'
    },
    anonymousResponses: {
        defaultMessage: 'Your individual responses are always anonymous.',
        description: 'label assuring users their survey responses are anonymous'
    }
})

export interface BaselineAssessmentSuperlativeCardProps {
    size?: 'small' | 'medium'
    baselineAssessmentLatestAttempt: AssessmentAttemptSuccess
    canClose?: boolean
    onClose?: () => void
    showConfetti?: boolean
}

const LOW_SCORE = 0.5
const linkPath =
    '/journeys?baselineAssessment=true&utm_source=superlativeShareLink'

const BaselineAssessmentSuperlativeCard: React.FC<
    BaselineAssessmentSuperlativeCardProps
> = ({
    size = 'medium',
    canClose = false,
    onClose,
    baselineAssessmentLatestAttempt,
    showConfetti
}) => {
    const { formatMessage } = useIntl()
    const generateTeamsDeepLink = useGenerateTeamsDeepLink()
    const isSmallScreen = useIsSmallScreen()
    const isSmallLayout = isSmallScreen || size === 'small'
    const theme = useTheme()
    const {
        assessmentId,
        name: assessmentName,
        variant: assessmentVariant,
        assessmentAttemptId,
        superlative
    } = baselineAssessmentLatestAttempt

    const isLowScore = superlative?.score && superlative?.score <= LOW_SCORE
    const superlativeTitle = isLowScore
        ? superlative?.lowScoreTitle
        : superlative?.title
    const superlativeDescription = isLowScore
        ? superlative?.lowScoreDescription
        : superlative?.description

    const copyLink = useMemo(() => {
        const title = formatMessage(messages.shareLinkTitle, {
            title: superlativeTitle
        })
        if (isMicrosoftTeamsTest()) {
            const deepLink = generateTeamsDeepLink(
                linkPath.substring(1)
            ).toString()
            return `${title}\n${deepLink}`.trim()
        } else {
            const url = new URL(linkPath, window.location.origin)
            return `${title}\n${url.toString()}`.trim()
        }
    }, [formatMessage, generateTeamsDeepLink, superlativeTitle])

    const journeyColorPalette = useMemo(
        () => getJourneyColorPalette(theme, superlative?.journey?.coreType),
        [superlative, theme]
    )

    return (
        <LeafFixedMediaCard
            data-testid="baseline-assessment-superlative-card"
            alignMedia={isSmallLayout ? 'top' : 'left'}
            media={
                <React.Fragment>
                    <CardMedia
                        title=""
                        image={superlative?.imageUrl}
                        sx={{
                            height: 'auto',
                            backgroundSize: 'contain',
                            backgroundColor: journeyColorPalette.main
                        }}
                    />
                    {canClose && isSmallLayout && (
                        <Stack position="absolute" top={16} right={16}>
                            <LeafCloseButton onClick={onClose} />
                        </Stack>
                    )}
                </React.Fragment>
            }
            mediaWidth="md"
        >
            {showConfetti && (
                <Box
                    sx={{
                        position: 'fixed',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <ConfettiExplosion show={true} />
                </Box>
            )}
            <Stack
                px={2}
                pt={isSmallLayout ? 2 : 3.5}
                pb={isSmallLayout ? 3 : 4.5}
            >
                <Stack gap={isSmallLayout ? 1 : 1.5}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <CoreTypography variant="h3">
                            {superlativeTitle}
                        </CoreTypography>
                        {canClose && !isSmallLayout && (
                            <LeafCloseButton onClick={onClose} />
                        )}
                    </Stack>
                    <Stack gap={3}>
                        <CoreTypography variant="body1" color="primary">
                            {superlativeDescription}
                        </CoreTypography>
                        <Stack
                            alignItems="start"
                            gap={isSmallLayout ? 3 : 2}
                            justifyContent="space-between"
                            direction={isSmallLayout ? 'column' : 'row'}
                        >
                            <Stack direction="row" alignItems="center" gap={1}>
                                <LeafIcon icon="lock" fontSize="small" />
                                <CoreTypography
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {formatMessage(messages.anonymousResponses)}
                                </CoreTypography>
                            </Stack>
                            <Box
                                width={isSmallLayout ? '100%' : 'initial'}
                                minWidth={167}
                            >
                                <LeafCopyButton
                                    fullWidth
                                    size="medium"
                                    color="primary"
                                    variant="contained"
                                    linkBody={copyLink}
                                    startIcon={
                                        <LeafIcon
                                            icon="share-nodes"
                                            fontSize="small"
                                        />
                                    }
                                    label={formatMessage(messages.shareBtn)}
                                    toolTipPosition="top"
                                    onCopied={() => {
                                        Avo.surveyShared({
                                            featureType: 'assessment',
                                            activityType:
                                                'baseline_assessment_shared',
                                            isSystemEvent: false,
                                            assessmentId,
                                            assessmentName,
                                            assessmentVariant,
                                            assessmentAttemptId
                                        })
                                    }}
                                />
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </LeafFixedMediaCard>
    )
}

const BaselineAssessmentSuperlativeCardWithErrorBoundary: React.FC<
    BaselineAssessmentSuperlativeCardProps
> = (props) => {
    return (
        <ErrorBoundary
            {...defaultErrorBoundaryProps}
            boundaryName="BaselineAssessmentSuperlativeCard"
            boundaryType="Component"
        >
            <BaselineAssessmentSuperlativeCard {...props} />
        </ErrorBoundary>
    )
}

export default BaselineAssessmentSuperlativeCardWithErrorBoundary
