import {
    AlertTitle,
    Box,
    Card,
    CardContent,
    Fade,
    LinearProgress,
    Skeleton,
    Stack,
    SxProps
} from '@mui/material'
import { defineMessages, useIntl } from 'react-intl'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
    AssessmentAttemptError,
    AssessmentAttemptSuccess,
    AssessmentItem,
    AssessmentType
} from '../../../graphql/generated/autogenerated'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import {
    CoreAlert,
    CoreTypography,
    ErrorScreenVariant,
    LeafErrorCode
} from '@thriveglobal/thrive-web-leafkit'
import AssessmentStep, { AssessmentStepStyleProps } from './AssessmentStep'
import { ApolloError } from '@apollo/client'
import PrivacyNotice from '../../../components/PrivacyNotice'
import { getAssessmentProgressCount } from '../../utils'
import { ActivityTypeValueType } from '@thriveglobal/thrive-web-tracking/dist/Avo'

const messages = defineMessages({
    currentStepHeader: {
        defaultMessage: '{currentStep} of {totalSteps}',
        description:
            'Describes current step of total steps in process. Ex: 1 of 5'
    },
    errorCodeMessage: {
        defaultMessage:
            "We're having trouble loading this page, please refresh the page or come back later.",
        description:
            'Description of technical difficulties. Asking the user to try again later.'
    },
    outroHeader: {
        defaultMessage: 'Thank you for investing in your well-being.',
        description: 'Thank you message for the end of the survey.'
    },
    outroSubHeader: {
        defaultMessage:
            'Your honest responses will help guide your experience.',
        description: "Sub header describing the impact of the user's action."
    },
    outroButtonLabel: {
        defaultMessage: 'Finish',
        description: 'Label for button that closes the survey.'
    },
    noResponsesTitleError: {
        defaultMessage: 'No questions have been answered.',
        description:
            'Alert that shows when a user has not answered any questions in the survey.'
    },
    noResponsesTextError: {
        defaultMessage:
            'Answer at least one question to finish your assessment.',
        description:
            'Alert that shows when a user has not answered any questions in the survey.'
    }
})

export interface AssessmentResponse {
    completed: boolean
    error?: any
    specificError?: AssessmentAttemptError
    assessmentAttempt?: AssessmentAttemptSuccess
}

export interface AssessmentProps extends AssessmentStepStyleProps {
    assessmentAttempt: AssessmentAttemptSuccess
    error: ApolloError
    assessmentAttemptError: AssessmentAttemptError
    loading: boolean
    assessmentItems: AssessmentItem[]
    onCompleteAssessment: (response: AssessmentResponse) => void
    skipValidation?: boolean
    retainProgress?: boolean
    wrapperVariant?: 'card' | 'dialog' | 'nowrapper'
    sx?: SxProps
    openedByShareLink?: boolean
    setShowLoader?: (showLoader: boolean) => void
}

const Assessment: React.FC<AssessmentProps> = ({
    assessmentAttempt,
    assessmentAttemptError,
    error,
    loading,
    assessmentItems,
    onCompleteAssessment,
    wrapperVariant = 'dialog',
    retainProgress = false,
    sx,
    setShowLoader,
    openedByShareLink,
    skipValidation = true,
    ...assessmentStepperStyleProps
}) => {
    const intl = useIntl()
    const [step, setStep] = useState<number>(1)
    const [latestAssessmentResponse, setLatestAssessmentResponse] =
        useState<AssessmentAttemptSuccess>()

    const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false)
    const [hasResponses, setHasResponses] = useState<boolean>(false)

    useEffect(() => {
        if (retainProgress && assessmentAttempt?.givenResponses?.length > 0) {
            setStep(
                getAssessmentProgressCount(assessmentAttempt.givenResponses) + 1
            )
        }
    }, [
        retainProgress,
        assessmentAttempt?.givenResponses,
        assessmentItems?.length,
        assessmentAttempt
    ])

    useEffect(() => {
        const checkHasResponses = (responses) =>
            responses?.some((givenResponse) => givenResponse.response !== null)

        const hasResponses =
            checkHasResponses(latestAssessmentResponse?.givenResponses ?? []) ||
            checkHasResponses(assessmentAttempt?.givenResponses ?? [])

        setHasResponses(hasResponses)
    }, [
        latestAssessmentResponse?.givenResponses,
        assessmentAttempt?.givenResponses
    ])

    useEffect(() => {
        setIsAlertOpen(false)
    }, [step])

    const assessmentError = useMemo(() => {
        return (
            error ||
            (assessmentAttemptError &&
                assessmentAttemptError?.errorCode ===
                    'ASSESSMENT_CMS_ASSESSMENT_NOT_FOUND') ||
            assessmentAttempt?.allQuestions?.length === 0
        )
    }, [assessmentAttempt?.allQuestions?.length, assessmentAttemptError, error])

    const activityType: ActivityTypeValueType = useMemo(() => {
        switch (assessmentAttempt?.type) {
            case AssessmentType.BaselineAssessment:
                if (openedByShareLink) {
                    return 'shared_baseline_assessment_completed'
                }
                return 'baseline_assessment_completed'
            case AssessmentType.ProgressAssessment:
                return 'progress_assessment_completed'
            default:
                return 'onboarding_assessment_completed'
        }
    }, [assessmentAttempt?.type, openedByShareLink])

    function completeWithoutErrors() {
        Avo.surveyCompleted({
            featureType: 'assessment',
            activityType: activityType,
            isOnboarding: true,
            assessmentId: assessmentAttempt?.assessmentId,
            assessmentAttemptId: assessmentAttempt?.assessmentAttemptId,
            assessmentName: assessmentAttempt?.name,
            assessmentVariant: assessmentAttempt?.variant,
            isSystemEvent: false
        })
        onCompleteAssessment({
            completed: true,
            error: error,
            specificError: assessmentAttemptError,
            assessmentAttempt: latestAssessmentResponse
        })
    }

    function completeWithErrors() {
        onCompleteAssessment({
            completed: false,
            error: error,
            specificError: assessmentAttemptError,
            assessmentAttempt: latestAssessmentResponse
        })
    }

    const nextStep = () => {
        if (step === assessmentItems?.length) {
            //if final step
            completeWithoutErrors()
        } else {
            //else next step
            setStep(step + 1)
        }
    }

    const prevStep = () => {
        setStep(step - 1)
    }

    const totalSteps = assessmentItems?.length || 0
    const progressBarValue = ((step - 1) / totalSteps) * 100

    const progressIndicatorId =
        assessmentAttempt.assessmentId + '-assessment-step-indicator'

    const ProgressIndicator = useCallback(
        () => (
            <Stack gap={1} textAlign={'center'} py={1}>
                <CoreTypography variant={'overline'} id={progressIndicatorId}>
                    {intl.formatMessage(messages.currentStepHeader, {
                        currentStep: step,
                        totalSteps: totalSteps
                    })}
                </CoreTypography>
                <Box my={1}>
                    <LinearProgress
                        aria-labelledby={progressIndicatorId}
                        aria-hidden
                        variant={'determinate'}
                        value={progressBarValue}
                    />
                </Box>
            </Stack>
        ),
        [intl, progressBarValue, progressIndicatorId, step, totalSteps]
    )

    const AssessmentWrapper = useCallback(
        (props) => {
            switch (wrapperVariant) {
                case 'dialog':
                    return (
                        <Stack px={'2px'}>
                            <ProgressIndicator />
                            <Card sx={sx}>
                                <CardContent>{props.children}</CardContent>
                            </Card>
                        </Stack>
                    )
                case 'card':
                    return (
                        <Card sx={sx}>
                            <CardContent>{props.children}</CardContent>
                        </Card>
                    )
                case 'nowrapper':
                    return props.children
            }
        },
        [sx, wrapperVariant, ProgressIndicator]
    )
    return (
        <AssessmentWrapper>
            <Stack textAlign={'left'}>
                {loading && (
                    <Stack mt={2} gap={1} display={'flex'} width={'100%'}>
                        <Skeleton
                            variant={'text'}
                            sx={{ fontSize: '1.4rem' }}
                            width={'80px'}
                        />
                        <Skeleton
                            variant={'text'}
                            sx={{ fontSize: '2rem' }}
                            width={'33%'}
                        />
                        <Skeleton
                            variant={'rounded'}
                            sx={{ maxWidth: '100%' }}
                            width={'100%'}
                            height={'44px'}
                        />
                    </Stack>
                )}
                {error && (
                    <LeafErrorCode
                        variant={ErrorScreenVariant.ServerError}
                        navigationButtonProps={{
                            onClick: completeWithErrors
                        }}
                    />
                )}
                {!loading &&
                    !assessmentError &&
                    assessmentItems?.length > 0 && (
                        <>
                            {wrapperVariant === 'card' && <ProgressIndicator />}
                            {assessmentItems.map((assessmentItem, index) => {
                                const isActiveStep = index + 1 === step
                                return (
                                    <Fade
                                        key={index}
                                        in={isActiveStep}
                                        timeout={{
                                            enter: 300,
                                            exit: 0
                                        }}
                                    >
                                        <Box>
                                            <AssessmentStep
                                                assessmentItem={assessmentItem}
                                                step={index + 1}
                                                prevStep={prevStep}
                                                nextStep={nextStep}
                                                assessmentAttempt={
                                                    latestAssessmentResponse ??
                                                    assessmentAttempt
                                                }
                                                setLatestAssessmentResponse={
                                                    setLatestAssessmentResponse
                                                }
                                                retainProgress={retainProgress}
                                                sx={
                                                    isActiveStep
                                                        ? {
                                                              visibility:
                                                                  'visible',
                                                              display: 'flex'
                                                          }
                                                        : {
                                                              visibility:
                                                                  'hidden',
                                                              display: 'none'
                                                          }
                                                }
                                                setShowLoader={setShowLoader}
                                                hasResponses={hasResponses}
                                                setIsAlertOpen={setIsAlertOpen}
                                                skipValidation={skipValidation}
                                                {...assessmentStepperStyleProps}
                                            />
                                        </Box>
                                    </Fade>
                                )
                            })}
                            {!skipValidation && isAlertOpen && (
                                <Box my={1} sx={{ textAlignLast: 'left' }}>
                                    <CoreAlert
                                        onClose={() => setIsAlertOpen(false)}
                                        severity="warning"
                                        variant="standard"
                                        data-testid="alert-null-answer"
                                    >
                                        <AlertTitle>
                                            <CoreTypography
                                                component="h5"
                                                variant="body1"
                                            >
                                                {intl.formatMessage(
                                                    messages.noResponsesTitleError
                                                )}
                                            </CoreTypography>
                                        </AlertTitle>
                                        <CoreTypography
                                            component="h5"
                                            variant="body1"
                                        >
                                            {intl.formatMessage(
                                                messages.noResponsesTextError
                                            )}
                                        </CoreTypography>
                                    </CoreAlert>
                                </Box>
                            )}
                            <PrivacyNotice />
                        </>
                    )}
            </Stack>
        </AssessmentWrapper>
    )
}

export default Assessment
