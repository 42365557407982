import {
    Button,
    CardMedia,
    SxProps,
    useMediaQuery,
    CardActions,
    CardHeader,
    CardContent
} from '@mui/material'
import {
    useAppSelector,
    useIsFeatureEnabled
} from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LeafFixedMediaCard,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { useCallback, useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    AssessmentAttemptSuccess,
    AssessmentStatus,
    ThriveReset,
    useGetRandomResetQuery
} from '../../../graphql/generated/autogenerated'
import ProgressAssessmentCompletedCard from './ProgressAssessmentCompletedCard'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import Assessment from '../Assessment'
import { useGetOrCreateNewProgressAssessment } from '../../../hooks/useGetOrCreateNewProgressAssessment'
import ProgressAssessmentEligibleCard from './ProgressAssessmentEligibleCard'

export interface ProgressAssessmentProps {
    sx?: SxProps
}

const messages = defineMessages({
    cardHeader: {
        defaultMessage: 'Reflect on your progress',
        description:
            'Title of section that prompts the user to take a well-being assessment'
    },
    cardBody: {
        defaultMessage:
            'Answer 4-5 short questions to help you reflect and allow Thrive to suggest better, more personalized insights and recommendations. It should only take about three minutes!',
        description:
            'Brief description of the assessment the user is being asked to take.'
    },
    dismissButtonLabel: {
        defaultMessage: 'Maybe later',
        description:
            "Label for button that will dismiss the user's assessment for now"
    },
    dismissButtonAriaLabel: {
        defaultMessage: 'Skip assessment right now',
        description:
            "Accessible label for button that will dismiss the user's assessment for now"
    },
    takeAssessmentButtonLabel: {
        defaultMessage: 'Take assessment',
        description: "Button text that will start the user's assessment."
    }
})

const ASSESSMENT_CARD_THUMBNAIL =
    'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/b3234b2f-fd9f-48a2-845f-120ae1863100/cms'

const ProgressAssessment: React.FC<ProgressAssessmentProps> = ({ sx = {} }) => {
    const intl = useIntl()
    const theme = useTheme()
    const smDown = useMediaQuery(theme.breakpoints.down('sm'))
    const { userId } = useAppSelector((state) => state.user)

    const showProgressAssessment = useIsFeatureEnabled(
        'showProgressAssessment',
        false,
        true
    )

    const useNewCallToAction = useIsFeatureEnabled(
        'useProgressAssessmentCTAV2',
        false,
        true
    )

    const [eligibleForAssessment, setEligibleForAssessment] =
        useState<boolean>(false)
    const [assessmentStatus, setAssessmentStatus] =
        useState<AssessmentStatus>(null)
    const [assessmentStarted, setAssessmentStarted] = useState<boolean>(false)
    const [dismissedForNow, setDismissedForNow] = useState<boolean>(false)

    const {
        loading: resetLoading,
        data: resetData,
        error: resetError
    } = useGetRandomResetQuery()

    const onAssessmentAttemptLoaded = useCallback(
        (attemptSuccess: AssessmentAttemptSuccess) => {
            Avo.surveyServed({
                featureType: 'assessment',
                activityType: 'progress_assessment_served',
                isOnboarding: false,
                assessmentId: attemptSuccess.assessmentId,
                assessmentAttemptId: attemptSuccess.assessmentAttemptId,
                assessmentName: attemptSuccess.name,
                assessmentVariant: attemptSuccess.variant,
                userId_: userId,
                surveyLocation: null,
                dispatchedDate: null,
                isSystemEvent: false,
                onboardingAttemptNumber: null
            })
            setAssessmentStatus(attemptSuccess.status)
        },
        [userId]
    )

    const handleDismissForNow = () => {
        setDismissedForNow(true)
        Avo.promptSelected({
            featureType: 'assessment',
            activityType: 'progress_assessment_deferred',
            contentId: null,
            contentType: null,
            contentTitle: null,
            contentSubtype: null,
            contentFormatType: null,
            contentUrl: null,
            contentSource: null,
            resetId: null,
            resetName: null,
            isOnboarding: false,
            microstepId: null,
            microstepBody: null,
            isAutomaticCheckin: false,
            microstepName: null,
            checkInDate: null,
            userId_: null,
            displayText: messages.dismissButtonLabel.defaultMessage,
            tabName: null,
            tstPlatform: null,
            notificationCount: 0,
            nudgeType: null,
            isSystemEvent: false
        })
    }

    const handleAssessmentStarted = () => {
        setAssessmentStarted(true)
        Avo.surveyStarted({
            featureType: 'assessment',
            activityType: 'progress_assessment_started',
            isOnboarding: false,
            assessmentId: assessmentAttempt?.assessmentId,
            assessmentAttemptId: assessmentAttempt?.assessmentAttemptId,
            assessmentName: assessmentAttempt?.name,
            assessmentVariant: assessmentAttempt?.variant,
            isSystemEvent: false,
            onboardingAttemptNumber: null,
            responseText: null
        })
    }

    const {
        getOrCreateNewProgressAssessment,
        loading,
        error,
        assessmentAttempt,
        assessmentItems,
        getInProgressOrCreateNewProgressAssessmentError
    } = useGetOrCreateNewProgressAssessment(onAssessmentAttemptLoaded)

    useEffect(() => {
        if (showProgressAssessment) {
            getOrCreateNewProgressAssessment()
        }
    }, [getOrCreateNewProgressAssessment, showProgressAssessment])

    useEffect(() => {
        if (
            error ||
            loading ||
            getInProgressOrCreateNewProgressAssessmentError ||
            assessmentAttempt === undefined ||
            assessmentItems?.length === 0
        ) {
            setEligibleForAssessment(false)
        } else {
            setEligibleForAssessment(true)
        }
    }, [
        assessmentAttempt,
        assessmentItems?.length,
        error,
        getInProgressOrCreateNewProgressAssessmentError,
        loading
    ])

    const ProgressAssessmentCTA01 = (
        <LeafFixedMediaCard
            alignMedia={smDown ? 'top' : 'left'}
            media={<CardMedia image={ASSESSMENT_CARD_THUMBNAIL} />}
        >
            <CardHeader
                title={intl.formatMessage(messages.cardHeader)}
                titleTypographyProps={{ variant: 'h5' }}
            />
            <CardContent sx={{ py: 0 }}>
                <CoreTypography variant={'body1'}>
                    {intl.formatMessage(messages.cardBody)}
                </CoreTypography>
            </CardContent>
            <CardActions>
                <Button
                    variant={'contained'}
                    color={'info'}
                    onClick={handleDismissForNow}
                    aria-label={intl.formatMessage(
                        messages.dismissButtonAriaLabel
                    )}
                >
                    <CoreTypography customVariant={'buttonNormal'}>
                        {intl.formatMessage(messages.dismissButtonLabel)}
                    </CoreTypography>
                </Button>
                <Button variant={'contained'} onClick={handleAssessmentStarted}>
                    <CoreTypography customVariant={'buttonNormal'}>
                        {intl.formatMessage(messages.takeAssessmentButtonLabel)}
                    </CoreTypography>
                </Button>
            </CardActions>
        </LeafFixedMediaCard>
    )

    if (!showProgressAssessment || !eligibleForAssessment || dismissedForNow) {
        return null
    } else if (assessmentStatus === AssessmentStatus.Completed && !resetError) {
        return (
            <ProgressAssessmentCompletedCard
                reset={resetData?.reset?.thrive?.random as ThriveReset}
                loading={resetLoading}
                sx={sx}
            />
        )
    } else if (!assessmentStarted) {
        if (useNewCallToAction) {
            return (
                <ProgressAssessmentEligibleCard
                    handleDismissForNow={handleDismissForNow}
                    handleAssessmentStarted={handleAssessmentStarted}
                    sx={sx}
                />
            )
        } else {
            return ProgressAssessmentCTA01
        }
    }

    function onCompleteAssessment(a: any) {
        setAssessmentStatus(AssessmentStatus.Completed)
    }

    return (
        <Assessment
            assessmentAttempt={assessmentAttempt}
            assessmentItems={assessmentItems}
            error={error}
            loading={loading}
            assessmentAttemptError={
                getInProgressOrCreateNewProgressAssessmentError
            }
            onCompleteAssessment={onCompleteAssessment}
            responseDirection={'column'}
            wrapperVariant={'card'}
            skipValidation
            sx={sx}
        />
    )
}

export default ProgressAssessment
