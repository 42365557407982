import { Box, Fade, Stack, SxProps, useTheme } from '@mui/material'
import { Avo, AvoTypes } from '@thriveglobal/thrive-web-tracking'
import {
    ErrorScreenVariant,
    LeafErrorCode
} from '@thriveglobal/thrive-web-leafkit'
import { ApolloError } from '@apollo/client'
import { defineMessages, useIntl } from 'react-intl'
import { useEffect, useMemo, useState } from 'react'
import {
    AssessmentAttemptError,
    AssessmentAttemptSuccess,
    AssessmentItem,
    AssessmentType,
    ResponseInput
} from '../../../graphql/generated/autogenerated'

import AssessmentStep, { AssessmentStepStyleProps } from './AssessmentStep'
import AssessmentWrapper, {
    AssessmentWrapperVariant
} from './AssessmentWrapper'
import AssessmentSkeleton from './AssessmentSkeleton'
import AssessmentProgressIndicator from './AssessmentProgressIndicator'
import AssessmentConfirmationDialog from './AssessmentConfirmationDialog'
import AssessmentAlert from './AssessmentAlert'

import {
    useAssessmentProgress,
    useAssessmentSubmission,
    useAssessmentValidation
} from './hooks'
import {
    useTrackAssessmentSurveyCompleted,
    useTrackAssessmentSurveyStarted
} from '../../../tracking'

export interface AssessmentResponse {
    completed: boolean
    error?: any
    specificError?: AssessmentAttemptError
    assessmentAttempt?: AssessmentAttemptSuccess
}

export interface AssessmentProps extends AssessmentStepStyleProps {
    assessmentAttempt: AssessmentAttemptSuccess
    error: ApolloError
    assessmentAttemptError: AssessmentAttemptError
    loading: boolean
    assessmentItems: AssessmentItem[]
    onCompleteAssessment: (response: AssessmentResponse) => void
    skipValidation?: boolean
    retainProgress?: boolean
    wrapperVariant?: AssessmentWrapperVariant
    sx?: SxProps
    openedByShareLink?: boolean
    setShowLoader?: (showLoader: boolean) => void
}

const messages = defineMessages({
    noResponsesTitleError: {
        defaultMessage: 'No questions have been answered.',
        description:
            'Alert that shows when a user has not answered any questions in the survey.'
    },
    noResponsesDescriptionError: {
        defaultMessage:
            'Answer at least one question to finish your assessment.',
        description:
            'Alert that shows when a user has not answered any questions in the survey.'
    }
})

const Assessment: React.FC<AssessmentProps> = ({
    assessmentAttempt,
    assessmentAttemptError,
    error,
    loading,
    assessmentItems,
    onCompleteAssessment,
    wrapperVariant = AssessmentWrapperVariant.DIALOG,
    retainProgress = false,
    sx,
    setShowLoader,
    openedByShareLink,
    skipConfirmationDialog,
    skipValidation = true,
    ...assessmentStepperStyleProps
}) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const [latestAssessmentResponse, setLatestAssessmentResponse] =
        useState<AssessmentAttemptSuccess>()
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
        useState<boolean>(false)
    const [selectedInputResponse, setSelectedInputResponse] =
        useState<ResponseInput>()

    const {
        step,
        currentAssessmentItem,
        nextStep,
        prevStep,
        isFirstStep,
        isFinalStep,
        totalSteps
    } = useAssessmentProgress(
        assessmentAttempt,
        assessmentItems,
        retainProgress
    )
    const { isValid } = useAssessmentValidation(
        assessmentAttempt,
        latestAssessmentResponse
    )
    const submit = useAssessmentSubmission(assessmentAttempt)
    const trackAssessmentSurveyCompleted = useTrackAssessmentSurveyCompleted()
    const trackAssessmentSurveyStarted = useTrackAssessmentSurveyStarted()

    const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false)

    useEffect(() => {
        setIsAlertOpen(false)
    }, [step])

    const assessmentError = useMemo(() => {
        return (
            error ||
            (assessmentAttemptError &&
                assessmentAttemptError?.errorCode ===
                    'ASSESSMENT_CMS_ASSESSMENT_NOT_FOUND') ||
            assessmentAttempt?.allQuestions?.length === 0
        )
    }, [assessmentAttempt?.allQuestions?.length, assessmentAttemptError, error])

    const activityType: AvoTypes.ActivityTypeValueType = useMemo(() => {
        switch (assessmentAttempt?.type) {
            case AssessmentType.BaselineAssessment:
                if (isFinalStep) {
                    if (openedByShareLink) {
                        return 'shared_baseline_assessment_completed'
                    }
                    return 'baseline_assessment_completed'
                }
                return 'baseline_assessment_submitted'
            case AssessmentType.ProgressAssessment:
                return 'progress_assessment_completed'
            default:
                return 'onboarding_assessment_completed'
        }
    }, [assessmentAttempt?.type, openedByShareLink, isFinalStep])

    // First survey started event when first question is rendered
    useEffect(() => {
        const activityType = openedByShareLink
            ? 'shared_baseline_assessment_started'
            : 'baseline_assessment_started'
        trackAssessmentSurveyStarted({
            activityType,
            assessmentAttempt
        })
    }, [])

    const submitAssessmentResponse = async (responseInput?: ResponseInput) => {
        if (!responseInput && !isValid(isFinalStep, skipValidation)) {
            setIsAlertOpen(true)
            return
        }

        setSelectedInputResponse(responseInput)
        if (isFinalStep) {
            setShowLoader?.(true)
        }

        if (!isFinalStep) {
            nextStep()
        }

        const response = await submit(
            currentAssessmentItem,
            activityType,
            responseInput
        )

        const submitResponse = response.data?.assessment?.submitResponse

        const isSuccess =
            submitResponse?.__typename === 'AssessmentAttemptSuccess'

        if (isSuccess) {
            setLatestAssessmentResponse(submitResponse)
        }
        if (isFinalStep) {
            setShowLoader?.(false)

            if (isSuccess) {
                completeWithoutErrors()
            }
        }
    }

    const completeWithoutErrors = () => {
        trackAssessmentSurveyCompleted({
            activityType,
            assessmentAttempt
        })
        onCompleteAssessment({
            completed: true,
            error: error,
            specificError: assessmentAttemptError,
            assessmentAttempt: latestAssessmentResponse
        })
    }

    const completeWithErrors = () => {
        onCompleteAssessment({
            completed: false,
            error: error,
            specificError: assessmentAttemptError,
            assessmentAttempt: latestAssessmentResponse
        })
    }

    const progressIndicatorId = useMemo(() => {
        return `${assessmentAttempt.assessmentId}-assessment-step-indicator`
    }, [assessmentAttempt.assessmentId])

    const handleResponseInputChange = async (responseInput?: ResponseInput) => {
        if (isFinalStep && !skipConfirmationDialog) {
            setIsConfirmationDialogOpen(true)
            return
        }

        await submitAssessmentResponse(responseInput || selectedInputResponse)
    }
    return (
        <>
            <AssessmentWrapper
                isFinalStep={isFinalStep}
                isFirstStep={isFirstStep}
                wrapperVariant={wrapperVariant}
                ProgressIndicator={() => (
                    <AssessmentProgressIndicator
                        step={step}
                        totalSteps={totalSteps}
                        progressBarValue={((step - 1) / totalSteps) * 100}
                        progressIndicatorId={progressIndicatorId}
                    />
                )}
                Alert={() =>
                    !skipValidation && (
                        <AssessmentAlert
                            isOpen={isAlertOpen}
                            severity={'warning'}
                            title={formatMessage(
                                messages.noResponsesTitleError
                            )}
                            description={formatMessage(
                                messages.noResponsesDescriptionError
                            )}
                            onClose={() => setIsAlertOpen(false)}
                        />
                    )
                }
                handleBackClicked={() => prevStep()}
                handleNextClicked={handleResponseInputChange}
                sx={
                    isAlertOpen
                        ? {
                              ...sx,
                              border: `2px solid ${theme.palette.warning.main}`
                          }
                        : {
                              ...sx,
                              border: '2px solid transparent'
                          }
                }
            >
                <Stack textAlign={'left'}>
                    <AssessmentSkeleton loading={loading} />
                    {error && (
                        <LeafErrorCode
                            variant={ErrorScreenVariant.ServerError}
                            navigationButtonProps={{
                                onClick: completeWithErrors
                            }}
                        />
                    )}
                    {!loading &&
                        !assessmentError &&
                        !!assessmentItems?.length && (
                            <>
                                {assessmentItems.map(
                                    (assessmentItem, index) => {
                                        const isActiveStep = index + 1 === step
                                        return (
                                            <Fade
                                                key={index}
                                                in={isActiveStep}
                                                timeout={{
                                                    enter: 300,
                                                    exit: 0
                                                }}
                                            >
                                                <Box>
                                                    <AssessmentStep
                                                        assessmentItem={
                                                            assessmentItem
                                                        }
                                                        step={index + 1}
                                                        assessmentAttempt={
                                                            latestAssessmentResponse ??
                                                            assessmentAttempt
                                                        }
                                                        retainProgress={
                                                            retainProgress
                                                        }
                                                        sx={
                                                            isActiveStep
                                                                ? {
                                                                      visibility:
                                                                          'visible',
                                                                      display:
                                                                          'flex'
                                                                  }
                                                                : {
                                                                      visibility:
                                                                          'hidden',
                                                                      display:
                                                                          'none'
                                                                  }
                                                        }
                                                        handleResponseInputChange={
                                                            handleResponseInputChange
                                                        }
                                                        setSelectedInputResponse={
                                                            setSelectedInputResponse
                                                        }
                                                        {...assessmentStepperStyleProps}
                                                    />
                                                </Box>
                                            </Fade>
                                        )
                                    }
                                )}
                            </>
                        )}
                </Stack>
            </AssessmentWrapper>
            {!skipConfirmationDialog && (
                <AssessmentConfirmationDialog
                    isOpen={isConfirmationDialogOpen}
                    onClose={() => {
                        setIsConfirmationDialogOpen(false)
                    }}
                    onConfirm={async () => {
                        await submitAssessmentResponse(selectedInputResponse)
                        setIsConfirmationDialogOpen(false)
                    }}
                />
            )}
        </>
    )
}

export default Assessment
