import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardProps,
    Skeleton,
    Stack
} from '@mui/material'
import { defineMessages, useIntl } from 'react-intl'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import PrivacyNotice from '../../../components/PrivacyNotice'
import {
    Channel,
    DailyCheckInDto,
    DailyCheckInResponseInput,
    OptionsDto,
    SavePulseCheckInMutation,
    useSavePulseCheckInMutation
} from '../../../graphql/generated/autogenerated'
import React, { useEffect, useState } from 'react'
import { format, useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import {
    PULSE_CHECKIN_REQUEST_DATE_FORMAT,
    SURVEY_PREVIOUSLY_SAVED_STATUS,
    SURVEY_SAVED_STATUS
} from '../../../constants'
import { OtherThriveApps } from '../../../enums'
import { Avo } from '@thriveglobal/thrive-web-tracking'

export interface SubmitSurveyInputProps {
    dci: Partial<DailyCheckInDto>
    input: DailyCheckInResponseInput
    response: SavePulseCheckInMutation
}

interface DailyCheckInCardProps extends CardProps {
    dci: Partial<DailyCheckInDto>
    onSubmitSurvey?: (args: SubmitSurveyInputProps) => any
    isMakeup: boolean
    loading: boolean
}

const messages = defineMessages({
    makeupDciCardHeader: {
        defaultMessage: 'Makeup Check-In',
        description:
            "Header for the user's makeup daily check in question, this will allow the user to 'make up' for a previous day's check-in."
    },
    cardHeader: {
        defaultMessage: 'Daily Check-In',
        description: "Header for the user's daily check in question"
    },
    reflectionMessage: {
        defaultMessage:
            'By reflecting and responding on your well-being, you showed up today.',
        description:
            'This is a message shown to user after they answer their daily check in, meant to inspire satisfaction.'
    },
    continueToJourney: {
        defaultMessage: 'View my Microsteps',
        description:
            'Button text directing the user to the home page to view their Microsteps'
    }
})

const DailyCheckInCard: React.FC<DailyCheckInCardProps> = ({
    dci,
    onSubmitSurvey,
    isMakeup,
    loading,
    ...props
}) => {
    const intl = useIntl()
    const navigate = useCrossAppNavigation()

    const [savePulseCheckInMutation] = useSavePulseCheckInMutation()
    const [checkInSubmissionLoading, setCheckInSubmissionLoading] =
        useState<boolean>(false)
    const [checkInCompleted, setCheckInCompleted] = useState<boolean>(undefined)

    useEffect(() => {
        setCheckInCompleted(
            dci?.status === SURVEY_SAVED_STATUS ||
                dci?.status === SURVEY_PREVIOUSLY_SAVED_STATUS
        )
    }, [dci?.status])

    function onSubmitCheckIn(selectedOption: OptionsDto) {
        const currentDate = format(
            new Date(),
            PULSE_CHECKIN_REQUEST_DATE_FORMAT
        )

        const responseInput: DailyCheckInResponseInput = {
            respondingChannel: Channel.Web,
            responseDate: dci?.dispatchDate,
            userCurrentDate: currentDate,
            responseValue: selectedOption.value
        } as DailyCheckInResponseInput

        setCheckInSubmissionLoading(true)

        savePulseCheckInMutation({
            variables: {
                responseInputData: responseInput
            }
        }).then((r) => {
            if (onSubmitSurvey) {
                onSubmitSurvey({ dci, input: responseInput, response: r.data })
            }
            setCheckInSubmissionLoading(false)
            Avo.surveySubmitted({
                activityType: 'dci_submitted',
                featureType: 'journeys',
                responseValue: selectedOption.value,
                responseText: selectedOption.text,
                journey: null,
                journeyDay: null,
                journeyId: dci?.survey?.journeyId,
                journeyLevel: null,
                assessmentAttemptId: null,
                assessmentId: null,
                assessmentItemId: null,
                assessmentName: null,
                assessmentVariant: null,
                dciCount: null,
                firstJourney: null,
                isOnboarding: false,
                npsScore: null,
                openAnswer: null,
                tabName: null,
                surveyLocation: null,
                messageQueueId: null,
                dispatchedDate: dci?.dispatchDate,
                isSystemEvent: false
            })
            const surveyStatus =
                r.data?.pulse.surveys.saveCheckInResponse.savedCheckIn.status
            if (
                r.errors === undefined &&
                (surveyStatus === SURVEY_SAVED_STATUS ||
                    surveyStatus === SURVEY_PREVIOUSLY_SAVED_STATUS)
            ) {
                setCheckInCompleted(true)
            }
        })
    }

    function onContinueToJourneyClicked() {
        navigate(OtherThriveApps.ThriveRoot)

        Avo.promptSelected({
            featureType: 'journeys',
            activityType: 'navigate_to_journey_selected',
            contentId: null,
            contentType: null,
            contentTitle: null,
            contentSubtype: null,
            contentFormatType: null,
            contentUrl: null,
            contentSource: null,
            resetId: null,
            resetName: null,
            isOnboarding: false,
            userId_: null,
            displayText: messages.continueToJourney.defaultMessage,
            tabName: null,
            tstPlatform: null,
            checkInDate: null,
            isAutomaticCheckin: null,
            microstepBody: null,
            microstepId: null,
            microstepName: null,
            notificationCount: null,
            nudgeType: null,
            isSystemEvent: false
        })
    }

    const CardContentLoading = () => {
        return (
            <>
                <CardContent sx={{ py: 0 }}>
                    <Skeleton
                        variant={'rounded'}
                        height={'100px'}
                        width={'100%'}
                    />
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                    <Skeleton
                        variant={'rounded'}
                        height={'44px'}
                        width={'60%'}
                    />
                </CardActions>
            </>
        )
    }

    const CardContentCheckinCompleted = () => {
        return (
            <>
                <CardContent sx={{ py: 0 }}>
                    <CoreTypography variant={'h3'} component={'p'}>
                        {intl.formatMessage(messages.reflectionMessage)}
                    </CoreTypography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        endIcon={
                            <LeafIcon icon={'arrow-right'} fontSize={'small'} />
                        }
                        onClick={onContinueToJourneyClicked}
                    >
                        <CoreTypography customVariant={'buttonNormal'}>
                            {intl.formatMessage(messages.continueToJourney)}
                        </CoreTypography>
                    </Button>
                </CardActions>
            </>
        )
    }

    const CardContentUnchecked = () => {
        return (
            <>
                <CardContent sx={{ py: 0 }}>
                    <Stack gap={2}>
                        <CoreTypography variant={'h3'} component={'h4'}>
                            {dci?.survey?.question?.text}
                        </CoreTypography>
                        {dci?.survey?.question?.options?.map(
                            (option, index) => (
                                <Button
                                    key={index}
                                    color={'secondary'}
                                    variant={'contained'}
                                    fullWidth
                                    onClick={() => onSubmitCheckIn(option)}
                                >
                                    <CoreTypography
                                        customVariant={'buttonNormal'}
                                    >
                                        {option.text}
                                    </CoreTypography>
                                </Button>
                            )
                        )}
                    </Stack>
                </CardContent>
                <CardActions>
                    <PrivacyNotice />
                </CardActions>
            </>
        )
    }

    return (
        <Card {...props}>
            <CardHeader
                title={
                    <CoreTypography variant={'overline'} component={'h3'}>
                        {intl.formatMessage(
                            isMakeup
                                ? messages.makeupDciCardHeader
                                : messages.cardHeader
                        )}
                    </CoreTypography>
                }
            />
            {loading || checkInSubmissionLoading ? ( //loading content
                <CardContentLoading />
            ) : checkInCompleted ? ( //not loading and check in completed
                <CardContentCheckinCompleted />
            ) : (
                //not loading and check in not yet completed (default)
                <CardContentUnchecked />
            )}
        </Card>
    )
}

export default DailyCheckInCard
